<script setup>
import {useStore} from "vuex";
import {computed} from "vue";
//----------------------------------------------------------------------
const store = useStore()
//----------------------------------------------------------------------
const user = computed( () => {return store.getters['userModule/userData']})
//----------------------------------------------------------------------
// показать/скрыть меню
const menu = () => store.commit('menuModule/toggleMenu')
</script>
<template>
  <nav class="main-header navbar navbar-expand navbar-white navbar-light justify-content-between">
      <div v-if="user['name']" class="p-2">
        {{ user['name'] }}
        <span v-if="user['role'] != 100">(Задача: Оптимизация доставки комплектующих для производственной компании)</span>
        <span v-else>(Все результаты оценки команды)</span>
      </div>
      <v-icon
          icon="mdi:menu"
          class="toggle-menu action-icon--black m-2"
          @click="menu"
      ></v-icon>
  </nav>
</template>