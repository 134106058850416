export function criteriaList(){
    const criteria =
        [
            {
                id: 1,
                title: 'Оптимизация маршрута',
                sections:
                    [
                        {
                            id: 1,
                            name: 'Эксперт оценивает от 1 до 10 баллов насколько предложенное решение позволяет оптимизировать общий маршрут, а также сократить возможные сроки и время доставки комплектующих.',
                            values: 10,
                            activeIndex: 0,
                        },
                    ]
            },
            {
                id: 2,
                title: 'Сокращение стоимости доставки',
                sections:
                    [
                        {
                            id: 2,
                            name: 'Эксперт оценивает от 1 до 10 баллов насколько предложенное решение позволяет уменьшить возможную стоимость доставки комплектующих.',
                            values: 10,
                            activeIndex: 0,
                        },

                    ]

            },
            {
                id: 3,
                title: 'Прототип',
                sections:
                    [
                        {
                            id: 3,
                            name: 'Эксперт оценивает от 1 до 10 баллов степень работоспособности предлагаемого решения: запускаемость кода, оформление кода, общая работоспособность прототипа (есть возможность протестировать решение).',
                            values: 10,
                            activeIndex: 0,
                        },

                    ]

            },
            {
                id: 4,
                title: 'Бизнес-архитектура решения',
                sections:
                    [
                        {
                            id: 4,
                            name: 'Эксперт оценивает от 1 до 10 баллов насколько предложенное решение реалистично и масштабируемо в рамках рынка.',
                            values: 10,
                            activeIndex: 0,
                        },

                    ]

            },
            {
                id: 5,
                title: 'Уровень реализации',
                sections:
                    [
                        {
                            id: 5,
                            name: 'Концепция/прототип и т.д., где 1 — концепция, 10 — рабочий MVP.',
                            values: 10,
                            activeIndex: 0,
                        },

                    ]

            },
        ];
    return criteria;


}