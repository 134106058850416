<script setup>
import {computed, onMounted, ref} from "vue";
import axios from "axios";
import {useStore} from "vuex";
const store = useStore()
// запрос на role делаем каждый раз, не только при авторизации
const userData = ref([])

onMounted( ()=>{
  try {
    init()
  } catch (err) {
    alert ('APPLICATION ERROR')
  }
})

const init = async () => {
  const urlData =  process.env.VUE_APP_ADMIN_URL + "/api/userData.php"

  // userId.value получен при авторизации
  await axios
      .get(`${urlData}?token=${token.value}&id=${userId.value}`)
      .then(response => (userData.value = response.data))

  // обновить данные о пользователе
  store.commit('userModule/setUserData', userData.value)
  store.commit('menuModule/initMenu')
}

//----------------------------------------------------------------------
const token = computed( () => {return store.getters['authModule/token']})
const userId = computed( () => {return store.getters['authModule/userId']})

</script>
<template>
  <main class="content-wrapper">
    <section class="content-header">
      <div class="container-fluid">
        <div class="row">
          <div class="col"><h3>О мероприятии</h3></div>
        </div>
      </div>
    </section>

    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">

            <div class="card card-outline card-info">
              <div class="card-header pt-3">
                <h4>Областное соревнование Челябинской области по спортивному программированию
                  <br>«Код индустрии» 2024 г.</h4>
              </div>
              <div class="card-body">

                <b>Цель:</b> развитие и популяризация спортивного программирования на территории Челябинской области.
                <br><br>
                <b>Формат хакатона:</b> гибридный.
                <br><br>
                <b>Место проведения:</b> с 4 октября по 8 октября онлайн, 9 октября на площадке «Челябинск-Сити», Зал № 1, по адресу: г. Челябинск, ул. Кирова 159.
                <br><br>
                <b>Участники соревнования:</b> Участниками соревнований могут стать студенты и выпускники ВУЗов от 17 до 25 лет, являющиеся гражданами Российской Федерации. В составе команды должно быть от 3 до 5 спортсменов.
                <br><br>
                <b>Задача:</b> Оптимизация доставки комплектующих для производственной компании.
                <br><br>
                <b>Кейсодержатель:</b> Акционерное общество «РТ-Техприемка».
                <br><br>
                <b>Описание задачи:</b> При производстве или сборке продукции требуется доставить множество комплектующих точно в нужное время — не раньше, но и не позже этапа, на котором они будут использованы.
                <br><br>
                <hr>

              </div>
            </div>


            <div class="card card-outline card-success">
              <div class="card-header pt-3">
                <h4>Организаторы</h4>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-md-4 col-sm-12 mb-4 text-center">
                    <img src="https://%D0%BA%D0%BE%D0%B4%D0%B8%D0%BD%D0%B4%D1%83%D1%81%D1%82%D1%80%D0%B8%D0%B8.%D1%80%D1%84/images/logo/rt-priemka-2.png" class="img-fluid">
                    <p class="mt-3">Акционерное общество<br>«РТ-Техприемка»</p>
                  </div>

                  <div class="col-md-4 col-sm-12 mb-4 text-center">
                    <img src="https://%D0%BA%D0%BE%D0%B4%D0%B8%D0%BD%D0%B4%D1%83%D1%81%D1%82%D1%80%D0%B8%D0%B8.%D1%80%D1%84/images/logo/fed-prog.png" class="img-fluid">
                    <p class="mt-3">Общественная физкультурно – спортивная организация «Федерация спортивного программирования»</p>
                  </div>

                  <div class="col-md-4 col-sm-12 mb-4 text-center">
                    <img src="https://%D0%BA%D0%BE%D0%B4%D0%B8%D0%BD%D0%B4%D1%83%D1%81%D1%82%D1%80%D0%B8%D0%B8.%D1%80%D1%84/images/logo/min-digit-ch-2.png" class="img-fluid">
                    <p class="mt-3">Министерство цифровых технологий и связи<br>Челябинской области</p>
                  </div>

                  <div class="col-md-4 col-sm-12 mb-4 text-center">
                    <img src="https://%D0%BA%D0%BE%D0%B4%D0%B8%D0%BD%D0%B4%D1%83%D1%81%D1%82%D1%80%D0%B8%D0%B8.%D1%80%D1%84/images/logo/digital174.png" class="img-fluid">
                    <p class="mt-3">Центр развития цифровых технологий<br>Челябинской области</p>
                  </div>

                  <div class="col-md-4 col-sm-12 mb-4 text-center">
                    <img src="https://%D0%BA%D0%BE%D0%B4%D0%B8%D0%BD%D0%B4%D1%83%D1%81%D1%82%D1%80%D0%B8%D0%B8.%D1%80%D1%84/images/logo/min-sport-ch.png" class="img-fluid">
                    <p class="mt-3">Министерство спорта<br>Челябинской области</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

  </main>
</template>

<style scoped>
.logo{
  max-width: 350px;
  max-height: 200px;
  -webkit-filter: saturate(0%);
  filter: saturate(0%);
}
</style>